import { mapHelper } from '@/utils/utils.js';

// 菜品状态
const saleStatus = [
  { text: '已下架', value: 0 },
  { text: '已上架', value: 1 },
  { text: '已售罄', value: 2 }
];
const { map: saleStatusMap, setOps: setSaleStatusOps } = mapHelper.setMap(saleStatus);

export { saleStatus, saleStatusMap, setSaleStatusOps };
