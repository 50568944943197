<template>
  <contentCard v-show="isAuth">
    <div slot="right-handle" class="my-order-button" @click="myOrderOnClick">我的订单</div>
    <date-select ref="date_select" v-model="selectedDate" @change="seledDateOnChange" />
    <div class="dish-type-dish-box">
      <div class="dish-type-list">
        <div
          class="dish-type-item"
          :class="{ active: selectedType === item.id }"
          v-for="(item, index) in typeList"
          :key="'lx' + index"
          @click="typeOnClick(item, index)"
        >
          <div class="type-name">{{ item.typeName }}</div>
          <div class="ycsd-text">{{ item.ycsdText }}</div>
          <div class="ygdcsd-text" v-if="item.ygdcsdText">{{ item.ygdcsdText }}</div>
        </div>
      </div>
      <div class="dish-select-box">
        <div class="kdsd-text">
          可订时段：<span class="value-text">{{ selectedValue.kdsdText }}</span>
        </div>
        <div class="dishs-box">
          <div class="dish-list">
            <div
              class="dish-item"
              v-for="(item, index) in dishPacksList"
              :key="selectedType + item.id + index"
              @click="dishOnClick(item, index)"
            >
              <div class="quantity-box" v-if="item.quantity && item.quantity > 0 && !item.ygdcsdText && item.saleStatus === 1">
                {{ item.quantity }}
              </div>
              <div class="dish-picture">
                <img v-if="item.imageUrl" class="dish-img" :src="item.imageUrl" />
                <img v-else class="dish-img" src="./img/zwtp.png" />
                <div v-if="item.saleStatus === 1 && !['', null, void 0, NaN, -1, '-1'].includes(item.perCount)" class="mexg-mask">
                  每人限购 {{ item.perCount }} 份
                </div>
                <div v-if="[0, 2].includes(item.saleStatus)" class="mask-box">{{ saleStatusMap[item.saleStatus] }}</div>
              </div>
              <div class="dish-name">{{ ['', void 0, null, NaN].includes(item.dishId) ? item.packageName : item.dishName }}</div>
              <div class="label-value-list">
                <div class="label-value-item">
                  <div class="label-text">销量</div>
                  <div class="value-text">{{ item.totalCount || 0 }}</div>
                </div>
                <div class="label-value-item" v-if="item.knCun !== Infinity">
                  <div class="label-text">库存</div>
                  <div class="value-text">
                    {{ item.knCun === Infinity ? '9999+' : item.knCun }}
                  </div>
                </div>
                <div class="label-value-item price">
                  <div class="label-text">¥</div>
                  <div class="value-text">{{ item.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tip-text">井亭未来社区-预约点餐服务，下单时请确认订单信息，如有异常请联系社区，联系方式：0574-55711314</div>
      </div>
      <div class="dining-car-box">
        <div class="dining-car-header">
          <div class="title-text">餐车</div>
          <div class="clear-hand" @click="clearEmptyOnClick"><van-icon name="delete-o" size="14" />清空餐车</div>
        </div>
        <div class="dishs-box">
          <div class="dish-cc-list">
            <div
              class="dish-item"
              :class="{ 'dish-disabled': item.ygdcsdText || item.saleStatus !== 1 }"
              v-for="(item, index) in dinCarList"
              :key="'cc' + index"
            >
              <div class="dish-info">
                <div class="dish-name">{{ ['', void 0, null, NaN].includes(item.dishId) ? item.packageName : item.dishName }}</div>
                <div class="price-text">¥{{ item.price }}</div>
              </div>
              <div class="dish-handle-box" v-if="!item.ygdcsdText && item.saleStatus === 1">
                <div class="handle minus" @click="minusOnClick(item, index)"></div>
                <div class="quantity-text">{{ item.quantity }}</div>
                <div class="handle add" @click="addOnClick(item, index)"></div>
              </div>
            </div>
          </div>
          <div class="total-price">
            <div class="total-text">合计：</div>
            <div class="price-text"><span>¥</span>{{ priceText }}</div>
          </div>
        </div>
        <div class="to-place-order" @click="nowDownOrderOnClick">去下单</div>
      </div>
    </div>
  </contentCard>
</template>

<script>
import contentCard from '../components/contentCard.vue';
import dateSelect from '../components/dateSelect.vue';
import { getPlaceTypeListUrl, getPlaceOrderListUrl, getElderCanteenAuthUrl, userInfoURL } from './api.js';
import { saleStatusMap } from './map.js';
import moment from 'moment';
import Big from 'big.js';
import { mapState } from 'vuex';

moment.locale('zh-cn');

export default {
  name: 'ytjEldCanMealsList',
  components: {
    contentCard,
    dateSelect
  },
  data() {
    return {
      saleStatusMap,
      firstInit: true,
      isAuth: false,
      selectedDate: void 0,
      selectedType: void 0,
      selectedValue: {},
      typeList: [],
      dishList: [],
      packList: [],
      dishPacksList: [],
      popupShow: false,
      timer: null,
      currentTime: new Date(),
      dinCarList: []
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId']),
    priceText() {
      return this.dinCarList
        .filter(item => !item.ygdcsdText)
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prve, next) => Big(prve).plus(next), 0);
    },
    totalQuantityText() {
      return this.dinCarList
        .filter(item => !item.ygdcsdText)
        .map(item => +item.quantity)
        .reduce((prve, next) => Big(prve).plus(next), 0);
    }
  },
  async created() {
    const userRes = await this.$axios.get(userInfoURL, { params: { userId: this.userId } }).catch(() => {});
    userRes.data = userRes.data || {};
    const authRes = await this.$axios.get(getElderCanteenAuthUrl, { params: { houseId: userRes.data.chosenSpaceId } }).catch(() => ({}));
    if (authRes.data === true) {
      this.isAuth = true;
      this.$store.commit('removeNoKeepAlive', 'serviceSystem'); // ytjEldCanMealsList  serviceSystem
      this.selectedDate = moment().format('YYYY-MM-DD');
      this.getList();
    } else {
      this.$store.commit('addNoKeepAlive', 'serviceSystem');
      this.$router.replace({
        name: 'ytjEldCanNoAuth'
      });
    }
  },
  mounted() {},
  methods: {
    getList() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      Promise.all([
        this.$axios.get(getPlaceTypeListUrl, { params: { saleDate: this.selectedDate }, noGlobalLoading: true }),
        this.$axios.get(getPlaceOrderListUrl, { params: { saleDate: this.selectedDate }, noGlobalLoading: true })
      ])
        .then(([typRes, disRes]) => {
          typRes.data = Array.isArray(typRes.data) ? typRes.data : [];
          disRes.data = Array.isArray(disRes.data) ? disRes.data : [];
          typRes.data.reverse();
          disRes.data.forEach(el => {
            el.quantity = 0;
            el.knCun = ['', void 0, null, NaN, '-1', -1].includes(el.setTotalCount) ? Infinity : el.setTotalCount - el.totalCount;
            el.valueId = !['', void 0, null, NaN].includes(el.dishId) ? 'dp' + el.id : 'tc' + el.id;
          });
          typRes.data.forEach(item => {
            item.ycsdText =
              item.supplyTimeStart && item.supplyTimeStart ? item.supplyTimeStart.slice(0, 5) + ' ~ ' + item.supplyTimeEnd.slice(0, 5) : '';
            item.kdsdStart =
              item.orderDayFlagStart && item.orderTimeStart
                ? moment(this.selectedDate)
                    .subtract(item.orderDayFlagStart === '0' ? item.orderDaysStart : 0, 'd')
                    .format('YYYY-MM-DD ' + item.orderTimeStart.slice(0, 6) + '00')
                : '';
            item.kdsdEnd =
              item.orderDayFlagEnd && item.orderTimeEnd
                ? moment(this.selectedDate)
                    .subtract(item.orderDayFlagEnd === '0' ? item.orderDaysEnd : 0, 'd')
                    .format('YYYY-MM-DD ' + item.orderTimeEnd.slice(0, 6) + '59')
                : '';
            item.kdsdText =
              item.kdsdStart && item.kdsdEnd
                ? moment(item.kdsdStart).format('M.D HH:mm') + ' ~ ' + moment(item.kdsdEnd).format('M.D HH:mm')
                : '不限时间';
            if (item.kdsdStart && item.kdsdEnd) {
              if (this.currentTime < new Date(item.kdsdStart)) {
                item.ygdcsdText = '未到订餐时段';
              } else if (this.currentTime > new Date(item.kdsdEnd)) {
                item.ygdcsdText = '已过订餐时段';
              } else {
                item.ygdcsdText = '';
              }
            } else {
              item.ygdcsdText = '';
            }
            item.dishList = disRes.data.filter(
              el => el.saleStatus !== 0 && !['', void 0, null, NaN].includes(el.dishId) && el.dishTypeId === item.id
            );
            item.packList = disRes.data.filter(
              el =>
                el.saleStatus !== 0 &&
                ['', void 0, null, NaN].includes(el.dishId) &&
                !['', void 0, null, NaN].includes(el.packageId) &&
                el.dishTypeId === item.id
            );
            item.dishPacksList = disRes.data.filter(el => el.dishTypeId === item.id && el.saleStatus !== 0);
            item.dishList.forEach(el => {
              el.supplyTimeStart = this.selectedDate + (item.supplyTimeStart ? ' ' + item.supplyTimeStart.slice(0, 6) + '00' : '');
              el.supplyTimeEnd = this.selectedDate + (item.supplyTimeEnd ? ' ' + item.supplyTimeEnd.slice(0, 6) + '59' : '');
              el.ycsdText = item.ycsdText;
              el.kdsdStart = item.kdsdStart;
              el.kdsdEnd = item.kdsdEnd;
              el.ygdcsdText = item.ygdcsdText;
            });
            item.packList.forEach(el => {
              el.supplyTimeStart = this.selectedDate + (item.supplyTimeStart ? ' ' + item.supplyTimeStart.slice(0, 6) + '00' : '');
              el.supplyTimeEnd = this.selectedDate + (item.supplyTimeEnd ? ' ' + item.supplyTimeEnd.slice(0, 6) + '59' : '');
              el.ycsdText = item.ycsdText;
              el.kdsdStart = item.kdsdStart;
              el.kdsdEnd = item.kdsdEnd;
              el.ygdcsdText = item.ygdcsdText;
              el.typeName = item.typeName;
            });
            item.dishPacksList.forEach(el => {
              el.supplyTimeStart = this.selectedDate + (item.supplyTimeStart ? ' ' + item.supplyTimeStart.slice(0, 6) + '00' : '');
              el.supplyTimeEnd = this.selectedDate + (item.supplyTimeEnd ? ' ' + item.supplyTimeEnd.slice(0, 6) + '59' : '');
              el.ycsdText = item.ycsdText;
              el.kdsdStart = item.kdsdStart;
              el.kdsdEnd = item.kdsdEnd;
              el.ygdcsdText = item.ygdcsdText;
              el.typeName = item.typeName;
            });
          });
          // typRes.data = typRes.data.filter(item => item.ygdcsdText !== '已过订餐时段');
          if (this.firstInit) {
            this.firstInit = false;
            const flag = typRes.data.some(item => {
              return !item.ygdcsdText && Array.isArray(item.dishPacksList) && item.dishPacksList.filter(el => el.saleStatus !== 0).length > 0;
            });
            if (flag) {
              this.selectedType = (typRes.data[0] || {}).id;
              Object.assign(this.selectedValue, typRes.data[0] || {});
              this.dishList = (typRes.data[0] || {}).dishList || [];
              this.packList = (typRes.data[0] || {}).packList || [];
              this.dishPacksList = (typRes.data[0] || {}).dishPacksList || [];
              this.typeList = typRes.data;
              this.$toast.clear();
              this.startSetInterval();
            } else {
              this.selectedDate = moment().add(1, 'd').format('YYYY-MM-DD');
              this.$nextTick(() => {
                this.$refs.date_select.getWeeksList(this.selectedDate);
              })
              this.getList();
            }
          } else {
            this.selectedType = (typRes.data[0] || {}).id;
            Object.assign(this.selectedValue, typRes.data[0] || {});
            this.dishList = (typRes.data[0] || {}).dishList || [];
            this.packList = (typRes.data[0] || {}).packList || [];
            this.dishPacksList = (typRes.data[0] || {}).dishPacksList || [];
            this.typeList = typRes.data;
            this.$toast.clear();
            this.startSetInterval();
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
    myOrderOnClick() {
      this.$router.push({
        name: 'ytjEldCanMyOrderList'
      });
    },
    seledDateOnChange(value) {
      this.selectedValue.kdsdText = '';
      this.dinCarList = [];
      this.getList();
    },
    startSetInterval() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.updateListYgdcsdText, 1000);
    },
    updateListYgdcsdText() {
      this.currentTime = new Date();
      this.typeList.forEach(item => {
        if (item.kdsdStart && item.kdsdEnd) {
          if (this.currentTime < new Date(item.kdsdStart)) {
            item.ygdcsdText = '未到订餐时段';
          } else if (this.currentTime > new Date(item.kdsdEnd)) {
            item.ygdcsdText = '已过订餐时段';
          } else {
            item.ygdcsdText = '';
          }
        } else {
          item.ygdcsdText = '';
        }
        item.dishList.forEach(el => {
          el.ygdcsdText = item.ygdcsdText;
        });
        item.packList.forEach(el => {
          el.ygdcsdText = item.ygdcsdText;
        });
        item.dishPacksList.forEach(el => {
          el.ygdcsdText = item.ygdcsdText;
        });
      });
    },
    typeOnClick(value, index) {
      Object.assign(this.selectedValue, value);
      this.selectedType = value.id;
      this.dishList = value.dishList || [];
      this.packList = value.packList || [];
      this.dishPacksList = value.dishPacksList || [];
    },
    dishOnClick(value, index) {
      if (value.saleStatus !== 1) return; // 非上架菜品，不能添加购物车
      if (value.ygdcsdText) return; // 非订餐时段内，不能添加购物车
      const perCount = ['', null, void 0, NaN, -1, '-1'].includes(value.perCount) ? Infinity : value.perCount; // 每人限购数
      const knCun = value.knCun || 0; // 库存数
      const maxQuantity = Math.min(perCount, knCun); // 该菜品允许购物车最大数量
      if (value.quantity < maxQuantity) {
        value.quantity++;
      }
      if (!this.dinCarList.some(item => item.valueId === value.valueId)) {
        this.dinCarList.push(value);
      }
    },
    minusOnClick(value, index) {
      if (value.quantity > 0) {
        value.quantity--;
      }
      if (value.quantity <= 0) {
        this.dinCarList.splice(index, 1);
      }
    },
    addOnClick(value, index) {
      if (value.saleStatus !== 1) return;
      if (value.ygdcsdText) return;
      const perCount = ['', null, void 0, NaN, -1, '-1'].includes(value.perCount) ? Infinity : value.perCount;
      const knCun = value.knCun || 0;
      const maxQuantity = Math.min(perCount, knCun);
      if (value.quantity < maxQuantity) {
        value.quantity++;
      }
    },
    clearEmptyOnClick() {
      this.dinCarList = [];
      this.typeList.forEach(item => {
        if (Array.isArray(item.dishPacksList) && item.dishPacksList.length > 0) {
          item.dishPacksList.forEach(el => {
            el.quantity = 0;
          });
        }
      });
    },
    nowDownOrderOnClick() {
      const list = this.dinCarList.filter(item => !item.ygdcsdText && item.saleStatus === 1);
      if (list.length === 0) {
        this.$toast({ message: '请先添加有效餐品之后在下单' });
        return;
      }
      sessionStorage.setItem('jingting_dining_car_list', JSON.stringify(this.$_.cloneDeep(list)));
      sessionStorage.setItem('jingting_dining_car_time', this.selectedDate);
      this.$router.push({
        name: 'ytjEldCanConfirmPlaceOrder'
      });
    }
  }
};
</script>

<style scoped lang="less">
.my-order-button {
  box-sizing: border-box;
  width: 108px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #1774fd;
  background-color: rgba(24, 116, 253, 0.1);
  font-size: 18px;
  color: #1874fd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dish-type-dish-box {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
  padding-right: 10px;
  display: flex;
}
.dish-type-list {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 130px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f6f7fa;
  border-radius: 0px 0px 0px 15px;
  .dish-type-item {
    box-sizing: border-box;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(51, 51, 51, 0.5);
    .type-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5em;
    }
    .ycsd-text {
      font-size: 15px;
      line-height: 1.5em;
    }
    .ygdcsd-text {
      font-size: 14px;
      line-height: 1.5em;
    }
    &.active {
      background-color: #1874fd;
      color: #ffffff;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.dish-select-box {
  box-sizing: border-box;
  flex-grow: 1;
  width: calc(100% - 424px);
  height: calc(100% - 10px);
  margin: 0px 10px;
  .kdsd-text {
    box-sizing: border-box;
    background-color: #f6f7fa;
    height: 48px;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 10px;
    .value-text {
      color: #d9021b;
    }
  }
  .dishs-box {
    height: calc(100% - 78px);
  }
  .dish-list {
    box-sizing: border-box;
    max-height: 100%;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    .dish-item {
      box-sizing: border-box;
      flex-shrink: 0;
      border-radius: 4px;
      width: calc(25% - 15px);
      margin-right: 15px;
      margin-bottom: 10px;
      position: relative;
      .quantity-box {
        box-sizing: border-box;
        position: absolute;
        right: 0px;
        top: 0px;
        height: 26px;
        min-width: 26px;
        border-radius: 13px;
        background-color: #f80000;
        z-index: 3;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(10px, -6px);
        padding: 0px 6px;
      }
      .dish-picture {
        border-radius: inherit;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        .dish-img {
          box-sizing: border-box;
          border-radius: inherit;
          width: 100%;
          height: 122px;
          object-fit: cover;
          display: block;
        }
        .mexg-mask {
          box-sizing: border-box;
          border-radius: 0px 0px 4px 4px;
          width: 100%;
          height: 26px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          background-color: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }
        .mask-box {
          box-sizing: border-box;
          border-radius: inherit;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          background-color: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
        }
      }
      .dish-name {
        box-sizing: border-box;
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .label-value-list {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 2px;
        height: 28px;
        .label-value-item {
          flex-shrink: 0;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          font-size: 13px;
          color: #818181;
          line-height: 20px;
          .label-text {
            margin-right: 5px;
          }
          &.price {
            color: #e02020;
            line-height: 20px;
            .label-text {
              margin-right: 0px;
            }
            .value-text {
              font-size: 24px;
              line-height: 28px;
            }
          }
        }
      }
      // &:nth-child(4n) {
      //   margin-right: 0px;
      // }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tip-text {
    box-sizing: border-box;
    height: 30px;
    background-color: #1874fd;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dining-car-box {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 274px;
  height: calc(100% - 10px);
  .dining-car-header {
    box-sizing: border-box;
    height: 30px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1874fd;
    padding: 0px 10px;
    .clear-hand {
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }
  .dishs-box {
    box-sizing: border-box;
    height: calc(100% - 78px);
    background-color: #f6f7fa;
    .dish-cc-list {
      box-sizing: border-box;
      height: calc(100% - 42px);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 10px 12px;
      .dish-item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 10px;
        padding-bottom: 10px;
        .dish-info {
          box-sizing: border-box;
          flex-grow: 1;
          font-size: 15px;
          line-height: 21px;
          color: #333333;
          .price-text {
            margin-top: 6px;
          }
        }
        .dish-handle-box {
          box-sizing: border-box;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          .handle {
            box-sizing: border-box;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 100% 100%;
            &.minus {
              background-image: url('./img/minus_handle.png');
            }
            &.add {
              background-image: url('./img/add_handle.png');
            }
          }
          .quantity-text {
            font-size: 14px;
            line-height: 20px;
            width: 36px;
            flex-shrink: 0;
            text-align: center;
          }
        }
        &.dish-disabled {
          .dish-info {
            color: rgba(51, 51, 51, 0.5);
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .total-price {
      box-sizing: border-box;
      padding: 0px 12px;
      height: 42px;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ebebeb;
      .price-text {
        font-size: 24px;
        color: #e02020;
        span {
          font-size: 16px;
        }
      }
    }
  }
  .to-place-order {
    box-sizing: border-box;
    height: 48px;
    background-color: #1874fd;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
